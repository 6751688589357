import React, {ReactNode} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {Theme, ThemeProvider} from '@mui/material/styles';
import Viewport from './viewport';
import Box from "@mui/material/Box";
import Footer from "./footer"
import Navbar from "./navbar"

interface Props {
  children: ReactNode
  theme: Theme
}
export default function Index({ children, theme }: Props) {
  return (
    <>
      <Viewport />
      <ThemeProvider theme={theme}>
        <Navbar />
        <main>
          {children}
        </main>
        <Box
          component={'footer'}
          sx={{
            backgroundColor: 'secondary.main',
            fontColor: 'common.white'
          }}
        >
          <Footer />
        </Box>
      </ThemeProvider>
    </>
  );
}
