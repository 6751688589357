import React, {ReactNode} from 'react';

import TopLayout from './components/layout';
import theme from './theme';

interface Props {
  element: ReactNode
}
export default function wrapWithProvider({ element }: Props) {
  return <TopLayout theme={theme}>{element}</TopLayout>;
}
