import React from 'react';
import {Grid, IconButton, Stack, Typography} from "@mui/material";
import {sectionSpacing} from "../../theme";
import {StaticImage} from "gatsby-plugin-image";
import Box from "@mui/material/Box";
import {Link} from "gatsby-material-ui-components";
import {Instagram, LinkedIn} from "@mui/icons-material";

export default () => {
    return (
        <Grid
            container
            justifyContent={'space-around'}
            alignItems={'stretch'}
            paddingY={sectionSpacing}
            textAlign={'center'}
        >
            <Grid
                item
                xs={12}
                md={4}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                sx={{
                    marginBottom: 4
                }}
            >
                <StaticImage
                    src={'../../images/logos/de-realisatie@2x.png'}
                    alt={'logo de realisatie'}
                    layout={'fixed'}
                    height={53}
                    style={{
                        marginBottom: 20
                    }}
                />
                <Box
                    marginTop={'auto'}
                >
                    <Typography
                        fontSize={12}
                        textTransform={'uppercase'}
                        display={'block'}
                        color={'common.white'}
                    >
                        © De Realisatie 2022 | <Link  color={'common.white'} href="https://www.dna-makelaars.nl/privacyverklaring.pdf" rel="noopener noreferrer" target="_blank">privacy
                        voorwaarden</Link>
                    </Typography>
                </Box>
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                display={'flex'}
                alignItems={'center'}
                flexDirection={'column'}
                sx={{
                    marginBottom: 4
                }}
            >
                <Box marginTop={{md: -5}} marginBottom={{md: 5}}>
                    <StaticImage
                        src={'../../images/logo-white.svg'}
                        layout={'fixed'}
                        alt={'logo white'}
                        style={{
                            marginBottom: 20
                        }}
                    />
                </Box>
                <Box
                    marginTop={'auto'}
                >
                    <Stack
                        direction={'row'}
                    >
                        <IconButton
                            href={'https://www.instagram.com/dna.makelaars/'}
                            target={"_blank"}
                            color={'primary'}
                        >
                            <Instagram color={'primary'}/>
                        </IconButton>
                        <IconButton
                            disabled
                            color={'inherit'}
                        >
                            <LinkedIn color={'primary'}/>
                        </IconButton>
                    </Stack>
                </Box>
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
            >
                <StaticImage
                    src={'../../images/logos/dna-kleur@2x.png'}
                    alt={'logo DNA Makelaars'}
                    layout={'fixed'}
                    height={53}
                    style={{
                        marginBottom: 20
                    }}
                />
                <Box
                    component={'address'}
                    marginTop={'auto'}
                >
                    <Link
                        href={'tel:0367370591'}
                        color={'common.white'}
                    >
                        036 737 05 91
                    </Link><br />
                    <Link
                        href={'mailto:info@dna-makelaars.nl'}
                        color={'common.white'}
                    >
                        info@dna-makelaars.nl
                    </Link><br />
                    <Link
                        href={'https://www.dna-makelaars.nl'}
                        target={'_blank'}
                        color={'common.white'}
                    >
                        www.dna-makelaars.nl
                    </Link>
                </Box>
            </Grid>
        </Grid>
    )
}