import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export const sectionSpacing = 10
export const sectionPadding = 10
export const TEXT_PADDING = 4

const theme = createTheme({
  palette: {
    background: {
      default: '#FAF6EF'
    },
    primary: {
      main: '#CDA559',
      light: '#FAF6EF'
    },
    divider: '#CDA559',
    secondary: {
      main: '#212830'
    }
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'filled'
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderColor: '#CDA559',
          backgroundColor: 'white',
        }
      },
    }
  },
  typography: {
    fontSize: 16,
    button: {
      fontFamily: [
        'Finlandica',
        'Arial'
      ].join(','),
      fontWeight: 'bold'
    },
    h2: {
      fontFamily: [
        'Unna',
        'Arial'
      ].join(',')
    },
    h3: {
      fontFamily: [
        'Finlandica',
        'Arial'
      ].join(',')
    },
    h4: {
      fontFamily: [
        'Finlandica',
        'Arial'
      ].join(',')
    },
    subtitle1: {
      fontFamily: [
        'Unna',
        'Arial',
      ].join(','),
    },
    fontFamily: [
      'Unna',
      'Arial',
    ].join(','),
  },

});

export default responsiveFontSizes(theme);
