import React, {useEffect, useState} from 'react'
import Toolbar from "@mui/material/Toolbar";
import {Button, IconButton} from "gatsby-material-ui-components";
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import {Menu} from "@mui/material";
import {StaticImage} from "gatsby-plugin-image";
import { globalHistory } from '@reach/router'
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";

interface MenuItemProps {
  url: string
  title: string
  handleClick: () => void
  isHome: boolean
}

const MenuLink = ({url, title, handleClick, isHome}: MenuItemProps) => {
  return (
    <Button
      to={url}
      key={title}
      onClick={handleClick}
      sx={{
        'color': isHome ? 'common.white' : 'common.black',
        //width: 60,
      }}
    >
      <Typography
        textAlign="center"
        fontFamily={'Finlandica'}
        fontWeight={'bold'}
      >
        {title}
      </Typography>
    </Button>
  )
}

const isBrowser = typeof window !== "undefined"

const Header = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);


  const [isHome, setIsHome] = useState(isBrowser && window.location.pathname == '/' )


  useEffect(() => {
    if (!(isBrowser)) {
      return;
    }
    return globalHistory.listen(({ action }) => {
      setIsHome(window.location.pathname === '/')
    })
  }, )

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 122,
    target: isBrowser ? window : undefined,
  });

  const [woningenMenuAnchorElNav, setWoningenMenuAnchorElNav] = React.useState<null | HTMLElement>(null);
  const open = Boolean(woningenMenuAnchorElNav);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setWoningenMenuAnchorElNav(event.currentTarget);
  };
  const handleClose = () => {
    setWoningenMenuAnchorElNav(null);
  };

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={trigger ? 4 : 0}
        color={trigger ? 'primary': 'transparent'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          paddingY: 2,
          transition: 'background-color 1s linear',
          background: 'transparent linear-gradient(0deg, #00000000 0%, #0000006E 100%, #000000B4 100%) 0% 0% no-repeat padding-box;',
          backgroundColor: trigger ? 'white' : 'transparent'

        }}
      >
        <Toolbar
          disableGutters
          component="nav"
          variant="regular"
          sx={{
            maxWidth: theme => theme.breakpoints.values.lg,
            width: '100%'
          }}
        >
          <Box
            sx={{
              display: { xs: 'flex', md: 'none' }
            }}
            flex={1}
            paddingX={2}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <StaticImage
              src={'../../images/icon.png'}
              width={40}
              alt={'logo'}
            />
            Wachters Zeewolde
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'flex', md: 'none' },
                flexDirection: 'row'
              }}
            >
              <MenuLink url={'/'} title={'Home'} handleClick={handleCloseNavMenu} isHome={false} />
              <MenuLink url={'/locatie'} title={'Locatie'} handleClick={handleCloseNavMenu} isHome={false} />
              <MenuLink url={'/zeewolde'} title={'Zeewolde'} handleClick={handleCloseNavMenu} isHome={false} />
              <MenuLink url={'/woningen#vrijstaande-woningen'} title={'Vrijstaande woningen'} handleClick={handleCloseNavMenu} isHome={false} />
              <MenuLink url={'/woningen#appartementen'} title={'appartementen'} handleClick={handleCloseNavMenu} isHome={false} />
              <MenuLink url={'/beschikbaar'} title={'Beschikbaar'} handleClick={handleCloseNavMenu} isHome={false} />
              <MenuLink url={'/extra'} title={'Extra'} handleClick={handleCloseNavMenu} isHome={false} />
              <MenuLink url={'/duurzaam'} title={'Duurzaam'} handleClick={handleCloseNavMenu} isHome={false} />
              <MenuLink url={'/contact'} title={'Contact'} handleClick={handleCloseNavMenu} isHome={false} />
            </Menu>
          </Box>
          <Box
            sx={{ display: { xs: 'none', md: 'flex' } }}
            width={'100%'}
            justifyContent={'space-around'}
            alignItems={'center'}
          >
            <MenuLink url={'/'} title={'Home'} handleClick={handleCloseNavMenu} isHome={isHome} />
            <MenuLink url={'/locatie'} title={'Locatie'} handleClick={handleCloseNavMenu} isHome={isHome} />
            <MenuLink url={'/zeewolde'} title={'Zeewolde'} handleClick={handleCloseNavMenu} isHome={isHome} />
            <MenuLink url={'/woningen'} title={'Woningen'} handleClick={handleClick} isHome={isHome} />
            <Menu
              id="woningen-menu"
              anchorEl={woningenMenuAnchorElNav}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'woningen-button',
              }}
              sx={{
                '& .MuiMenu-list': {
                  display: 'flex',
                  flexDirection: 'column',
                },
                  '& .MuiTypography-root ': {
                    textAlign: 'left'
                  }
              }}
            >
              <MenuLink
                handleClick={handleClose}
                url={'/woningen#vrijstaande-woningen'}
                title={'Vrijstaande woningen'}
                isHome={false}
              />
              <MenuLink
                handleClick={handleClose}
                url={'/woningen#appartementen'}
                title={'Appartementen'}
                isHome={false}
              />
            </Menu>
            <MenuLink url={'/beschikbaar'} title={'Beschikbaar'} handleClick={handleCloseNavMenu} isHome={isHome} />

            {isHome && !trigger?
                <StaticImage
                    src={'../../images/logo.svg'}
                    width={282}
                    alt={'logo'}
                />
              :
              <StaticImage
                src={'../../images/icon.png'}
                width={57}
                alt={'logo'}
              />
            }
            <MenuLink url={'/extra'} title={'Extra'} handleClick={handleCloseNavMenu} isHome={isHome} />
            <MenuLink url={'/duurzaam'} title={'Duurzaam'} handleClick={handleCloseNavMenu} isHome={isHome} />
            <MenuLink url={'/contact'} title={'Contact'} handleClick={handleCloseNavMenu} isHome={isHome} />
            <Button
              to={'/inschrijven'}
              color={'secondary'}
              variant={'contained'}
              disabled
            >
              Inschrijven
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Header
